<script>
import Vue, { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v3.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { usePhotoId } from '/~/composables/photo-ids'
import DrawerPhotoIdDetails from '/~/drawer/photo-ids/photo-id-details.vue'

export default {
  name: 'photo-id',
  components: {
    BaseAsidePage,
    DrawerActions,
  },
  extends: DrawerPhotoIdDetails,
  props: {
    sliderOptionsOverride: {
      type: Object,
      default: () => ({
        spaceBetween: 0,
        slidesPerView: 1,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      }),
    },
  },
  setup() {
    const router = useRouter()
    const { isDarkThemeForEwallet } = useCms()
    const { photoId, deletePhotoId } = usePhotoId()

    const documentEnding = computed(() => photoId.value.number.slice(-4))

    const actions = computed(() => {
      return [
        {
          icon: 'v2/heroic/trash',
          backgroundColor: 'bg-red-700',
          label: 'Delete',
          description: 'Delete this Photo ID',
          click: () => {
            bottomSheet.show('confirmation-modal', {
              to: 'menu-modal',
              props: {
                icon: 'points-only/trash',
                title: 'Are you sure you want to delete this Photo ID?',
                text: `By deleting this Photo ID you will remove the document ending in ${documentEnding.value} from your Photo IDs permanently.`,
                confirmButtonText: 'Delete Photo ID',
                onConfirm: onDeleteConfirmationModalConfirm,
              },
            })
          },
        },
      ]
    })

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deletePhotoId()
        router.push({ hash: '#profile-photo-ids' })
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      }
    }

    return {
      ...DrawerPhotoIdDetails.setup?.(...arguments),
      isDarkThemeForEwallet,
      actions,
    }
  },
}
</script>

<template>
  <base-aside-page :title="`${photoId.type} (${photoId.subType})`" no-padding>
    <base-loader v-if="isPhotoIdLoading" size="lg" class="py-[88px]" />
    <template v-else>
      <div class="relative mx-auto mb-10 w-[300px]">
        <div ref="carousel" class="swiper-container h-[174px] w-full">
          <div class="swiper-wrapper flex items-center">
            <div
              v-for="item in fetchedImages"
              :key="item.id"
              class="swiper-slide h-full w-full max-w-[300px]"
              style="transition: all 500ms"
            >
              <img
                v-if="item.src"
                v-bind="item"
                class="mx-auto max-h-full max-w-full overflow-hidden rounded-lg object-contain"
              />
              <base-placeholder
                v-else
                rounded
                theme="dark"
                type="fill"
                class="h-full w-full"
              />
            </div>
          </div>
        </div>
        <div
          v-show="swiper"
          :data-id="id"
          data-button="prev"
          role="button"
          class="absolute top-1/2 z-10 -ml-[45px] -translate-y-1/2 transform rounded-full p-[5px] md:flex"
          :class="{
            'bg-gray-400 text-white': !isDarkThemeForEwallet,
            'bg-white text-eonx-neutral-600': isDarkThemeForEwallet,
          }"
        >
          <base-icon svg="chevron-left" size="md" />
        </div>
        <div
          v-show="swiper"
          :data-id="id"
          data-button="next"
          role="button"
          class="absolute right-0 top-1/2 z-10 -mr-[45px] -translate-y-1/2 transform rounded-full p-[5px] md:flex"
          :class="{
            'bg-gray-400 text-white': !isDarkThemeForEwallet,
            'bg-white text-eonx-neutral-600': isDarkThemeForEwallet,
          }"
        >
          <base-icon svg="chevron-right" size="md" />
        </div>
      </div>
      <drawer-actions :actions="actions" class="mb-10 space-y-4 px-5" />
      <div
        class="mt-auto rounded-t-3xl bg-gray-50 px-5 py-10"
        :class="{
          'bg-light': !isDarkThemeForEwallet,
          'bg-gray-800 text-white': isDarkThemeForEwallet,
        }"
      >
        <dl class="grid grid-cols-2 gap-x-[5px] gap-y-4 rounded-lg">
          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon svg="v2/custom/globe" :size="24" class="mr-[5px]" />
            Country
          </dt>
          <dd class="font-bold">
            {{ photoId.country }}
          </dd>

          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon
              svg="v2/custom/identification"
              :size="24"
              class="mr-[5px]"
            />
            {{ `${photoId.type} Number` }}
          </dt>
          <dd class="font-bold">
            {{ photoId.number }}
          </dd>

          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon svg="v2/custom/user" :size="24" class="mr-[5px]" />
            Family Name
          </dt>
          <dd class="font-bold capitalize">
            {{ photoId.lastName }}
          </dd>

          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon svg="v2/custom/user" :size="24" class="mr-[5px]" />
            Given Name
          </dt>
          <dd class="font-bold capitalize">
            {{ photoId.firstName }}
          </dd>

          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon svg="v2/custom/calendar" :size="24" class="mr-[5px]" />
            Date of Birth
          </dt>
          <dd class="font-bold">
            {{ formatDate('daymonthyear', photoId.dateOfBirth) }}
          </dd>

          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon svg="v2/custom/globe" :size="24" class="mr-[5px]" />
            Issuing Authority
          </dt>
          <dd class="font-bold">
            {{ photoId.countryOfIssue }}
          </dd>

          <dt class="flex items-center text-eonx-neutral-600">
            <base-icon svg="v2/custom/calendar" :size="24" class="mr-[5px]" />
            {{ `${photoId.type} Expiry` }}
          </dt>
          <dd class="font-bold">
            {{ formatDate('daymonthyear', photoId.expiryDate) }}
          </dd>
        </dl>
      </div>
    </template>
  </base-aside-page>
</template>
